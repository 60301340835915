.cards-c-step1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-img-s1 {
  width: 100%;

  border-radius: 5px;
}
.tele-cards .my-lbutton {
  padding: 3px 7px;
}
.tele-card.tele-blue.heigth-tele-card.full-w-tele {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
}
.card-img-s1 {
  width: 250px;
  margin-right: 10px;
  height: 100%;
  object-fit: contain;
}
.tele-m-c-f1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.tele-cards.tele-more-space-c {
  padding: 5px 60px;
}
.tele-cards.tele-more-space-c.gold-card-s .tele-card {
  background-image: linear-gradient(145deg, gold, brown);
}
.my-lbutton .arrow-right-c {
  font-size: 12px !important;
  position: relative;
  right: 0px;

}
.my-lbutton:hover .arrow-right-c {
  position: relative;
  right: -5px;
  transition: 0.3s;
}
.my-lbutton.red-lb{
    background-image: linear-gradient(to right, #c60000, #9d0d00);

}
.countdown-css-c{
    display: flex;
    flex-direction: column;
    align-items: center;
}.countdown-css-c span{
    margin-bottom: 10px;
}
