.left-p-2 {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  color: black;
  height: 100%;
}
.left-p-21 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
}
.left-p-221 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  border-radius: 5px;

  background-color: rgb(238, 245, 254);
}
.left-p-2 .ant-row {
  height: 100%;
}
.left-p-2 .left-p-221 h5{
  color: black;
}
.left-p-21 .hover-card-b.selected-crd {
  border-radius: 20px;
  background: linear-gradient(-45deg, #beffca 0%, #a7dfb1 100%);
}
.left-p-21 .hover-card-b.selected-crd:hover svg {
  margin-right: -5px;
  margin-left: 5px;
  transition: 0.5s;
}
.left-p2-2 {
  background-color: black;
  padding: 5px;
  border-radius: 5px;
  color: white;
  height: 100%;
}
.price-cs-sd .left-p2-2{
  background-color: rgb(39, 39, 39);
}
.left-p2-221 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.left-p2-221 h5 {
  padding: 10px;
  font-size: 14px;
  margin: 5px 0px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(26 32 44);
  border-radius: 5px;
}
.left-p2-2 .ant-row {
  height: 100%;
}
.pricing-main-c1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.pricing-main-c2 {
  max-width: 1000px;
}
.pricing-main-c2 h3 {
  margin-bottom: 20px;
  text-align: center;
  color: white;
}
.pricing-b-c a {
  text-decoration: none;
}
.pricing-b-c {
  margin-right: 30px;
}
.left-p-21 .my-lbutton {
  padding: 5px 10px;
}
.snd-card-stitle-c {
  margin-top: 40px;
  text-align: center;
  color: white;
}
.price-main-page-c {
  height: 100vh;
  overflow-y: auto;
  background-image: url("../../images//FznC6ff.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200% 200%;
  animation: backgroundSizeAnimation 2s ease-in-out;
}
@keyframes backgroundSizeAnimation {
  0% {
    background-size: 100% 100%;
  }
  
  100% {
    background-size: 200% 200%;
  }
}
.btn-menu-top-r-p .my-lbutton {
  padding: 5px 10px;
}
.my-lbutton.black-my-button{
  background: linear-gradient(-45deg, #020c23, #020c23c4);
  color: white;
}
@media screen and (max-width: 680px) {
  .pricing-main-c2 h3 {
    margin-top: 10px;
  }
}
